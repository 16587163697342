import { template as template_db83d466dbd74d8abeadedbda93ef0c9 } from "@ember/template-compiler";
const FKControlMenuContainer = template_db83d466dbd74d8abeadedbda93ef0c9(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
