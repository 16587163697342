import { template as template_08f1c3e8c44a4887863b7b0d04aedc8c } from "@ember/template-compiler";
import { hash } from "@ember/helper";
const DropdownItem = template_08f1c3e8c44a4887863b7b0d04aedc8c(`
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownDivider = template_08f1c3e8c44a4887863b7b0d04aedc8c(`
  <li ...attributes><hr class="dropdown-menu__divider" /></li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownMenu = template_08f1c3e8c44a4887863b7b0d04aedc8c(`
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DropdownMenu;
