import { template as template_e11422620a4a4048aa5a02e249e58374 } from "@ember/template-compiler";
const WelcomeHeader = template_e11422620a4a4048aa5a02e249e58374(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
