import { template as template_ba87fa62e5f140a1a0b8fec98d18aeb1 } from "@ember/template-compiler";
const SidebarSectionMessage = template_ba87fa62e5f140a1a0b8fec98d18aeb1(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
